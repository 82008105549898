<template>
  <div class="home">
    <MiNavbar/>
    <MiSlider/>
    <b-row class="rowcirular">
      <b-col style="background-color: rgb(230, 193, 92);"><h1>Preescolar</h1></b-col>
      <b-col style="background-color: rgb(172, 126, 2);"><h1>Primaria</h1></b-col>
      <b-col style="background-color: rgb(216, 141, 56);"><h1>Secundaria</h1></b-col>
      <b-col style="background-color: #e25f23;"><h1>Bachillerato</h1></b-col>
      <b-col style="background-color: rgb(228, 85, 85);"><h1>Licenciatura</h1></b-col>
    </b-row>
    <div class="container" >
      <b-row class="mt-5 pt-5">
        <b-col class="">
          <h5>Calendario escolar: <code>2023</code></h5>
          <a href="#"><b-iconstack font-scale="10" animation="spin">
          <b-icon
            stacked
            icon="calendar2-week"
            animation="throb"
            variant="info"
            scale="0.75"
          ></b-icon>
        </b-iconstack></a>
        </b-col>
        <b-col>
          <h5>Directorio institucional</h5>
          <a href="#"><b-iconstack font-scale="10" animation="spin">
          <b-icon
            stacked
            icon="people"
            animation="throb"
            variant="info"
            scale="0.75"
          ></b-icon>
        </b-iconstack></a>
        </b-col>
        <b-col>
          <h5>Buzón</h5>
          <a href="#"><b-iconstack font-scale="10" animation="spin">
          <b-icon
            stacked
            icon="mailbox"
            animation="throb"
            variant="info"
            scale="0.75"
          ></b-icon>
        </b-iconstack></a>
        </b-col>
        <b-col>
          <h5>Contacto</h5>
          <a href="#"><b-iconstack font-scale="10" animation="spin">
          <b-icon
            stacked
            icon="person-check"
            animation="throb"
            variant="info"
            scale="0.75"
          ></b-icon>
        </b-iconstack></a>
        </b-col>
      </b-row>
    </div>
    <div class="container mt-5 mb-5">
      <h1>Noticias y eventos</h1>
      <div class="noticias">
        <b-list-group>
          <b-list-group-item>
            <b-badge href="#"  variant="warning" class="right">Publicado: 12/15/2023</b-badge>
            <b-card title="Title" img-src="https://placekitten.com/300/300"  img-alt="Card image" img-left img-width="120px" class="mb-3">
              <b-card-text>
                Some quick example text to build on the card and make up the bulk of the card's content.
              </b-card-text>
              <!-- <template #footer>
              </template> -->
            </b-card>
          </b-list-group-item>
          <b-list-group-item>
            <b-badge href="#"  variant="warning" class="right" >Publicado: 12/15/2023</b-badge>
            <b-card title="Title" img-src="https://placekitten.com/300/300"  img-alt="Card image" img-left img-width="120px" class="mb-3">
              <b-card-text>
                Some quick example text to build on the card and make up the bulk of the card's content.
              </b-card-text>
            </b-card>
          </b-list-group-item>
          <b-list-group-item>
            <b-badge href="#"  variant="warning" class="right">Publicado: 12/15/2023</b-badge>
            <b-card title="Title" img-src="https://placekitten.com/300/300"  img-alt="Card image" img-left img-width="120px" class="mb-3">
              <b-card-text>
                Some quick example text to build on the card and make up the bulk of the card's content.
                Some quick example text to build on the card and make up the bulk of the card's content.
                Some quick example text to build on the card and make up the bulk of the card's content.
                Some quick example text to build on the card and make up the bulk of the card's content.
              </b-card-text>
              <!-- <template #footer>
              </template> -->
            </b-card>
          </b-list-group-item>
          <b-list-group-item>
            <b-badge href="#"  variant="warning" class="right" >Publicado: 12/15/2023</b-badge>
            <b-card title="Title" img-src="https://placekitten.com/300/300"  img-alt="Card image" img-left img-width="120px" class="mb-3">
              <b-card-text>
                Some quick example text to build on the card and make up the bulk of the card's content.
              </b-card-text>
            </b-card>
          </b-list-group-item>
        </b-list-group>
      </div>
    </div>

  </div>

</template>

<script>
// @ is an alias to /src
import MiNavbar from '@/components/NavComponent.vue'
import MiSlider from '@/components/SliderComponent.vue'
export default {
  name: 'HomeView',
  components: {
    MiNavbar,
    MiSlider
  }
}
</script>
<style scoped lang="scss">
.rowcirular{
  margin-right: 0px;
  .col{
    h1{
      text-align: center;
    }
  }
  // background-color: purple;
  h1{
    color: white;
  }
  .list-group .list-group-item {
    background-color: #d5b59c !important;
  }
  .opciones .row{
    border-top: 0px;
  }

}
@media screen and (max-width: 600px) {
  .noticias{
    overflow: scroll;
    height: 400px;
}
}
</style>

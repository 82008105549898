<template>
  <b-card class="text-center">
    <div class="">
      <em>Todos los derechos reservados  {{ new Date().getFullYear() }}</em>
    </div>
  </b-card>
</template>
<script>
export default {
  name: 'MiFooter'
}
</script>
<style scoped lang="scss">
</style>

<template>
<div>
    <b-carousel
      id="carousel-1"
      v-model="slide"
      :interval="4000"
      controls
      indicators
      background="#ababab"
      class="d-block img-fluid w-100"
      style="text-shadow: 1px 1px 2px #333;"
    >
    <b-carousel-slide
      caption="First Slide"
      img-src="https://mi.medicover.mx/img/instga.jpg"
    ></b-carousel-slide>
    <b-carousel-slide
      caption="Second Slide"
      img-src="https://mi.medicover.mx/img/instga2.jpg"
    ></b-carousel-slide>
    <b-carousel-slide
      caption="Third Slide"
      img-src="https://picsum.photos/1024/480/?image=22"
    ></b-carousel-slide>
    <b-carousel-slide
      caption="Fourth Slide"
      img-src="https://picsum.photos/1024/480/?image=23"
    ></b-carousel-slide>
    </b-carousel>
  </div>

</template>
<script>
export default {
  name: 'MiSlider',
  props: {
    msg: String
  },
  data () {
    return {
      slide: 0,
      sliding: null
    }
  },
  methods: {
    onSlideStart (slide) {
      this.sliding = true
    },
    onSlideEnd (slide) {
      this.sliding = false
    }
  }
}
</script>
<style >
@media only screen and (min-width: 48em) {
  .carousel-item img {
     height:60vh !important;
  }
}

</style>

<template>
    <b-navbar toggleable="lg" type="light" variant="info">
      <b-navbar-brand href="#">
      <img src="https://mi.medicover.mx/img/logok.png" width="40" class="d-inline-block align-top" alt="Kitten">
       Instituto Gabriel Aguirre
    </b-navbar-brand>

    <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

    <b-collapse id="nav-collapse" is-nav>
      <b-navbar-nav>
        <b-nav-item href="#"><b-icon icon="facebook" scale="1"></b-icon></b-nav-item>
        <b-nav-item href="#"><b-icon icon="instagram" scale="1"></b-icon></b-nav-item>
        <b-nav-item href="#"><b-icon icon="whatsapp" scale="1"></b-icon></b-nav-item>
        <!-- <b-nav-item href="#">Link</b-nav-item>
        <b-nav-item href="#" disabled>Disabled</b-nav-item> -->
      </b-navbar-nav>

      <!-- Right aligned nav items -->
      <b-navbar-nav class="ml-auto">
        <!-- <b-nav-form>
          <b-form-input size="sm" class="mr-sm-2" placeholder="Search"></b-form-input>
          <b-button size="sm" class="my-2 my-sm-0" type="submit">Search</b-button>
        </b-nav-form> -->

        <!-- <b-nav-item-dropdown text="Lang" right>
          <b-dropdown-item href="#">EN</b-dropdown-item>
          <b-dropdown-item href="#">ES</b-dropdown-item>
          <b-dropdown-item href="#">RU</b-dropdown-item>
          <b-dropdown-item href="#">FA</b-dropdown-item>
        </b-nav-item-dropdown>

        <b-nav-item-dropdown right>
          <template #button-content>
            <em>User</em>
          </template>
          <b-dropdown-item href="#">Profile</b-dropdown-item>
          <b-dropdown-item href="#">Sign Out</b-dropdown-item>
        </b-nav-item-dropdown> -->
        <b-nav-item href="#">Agenda Telefonica</b-nav-item>
        <b-nav-item href="#">Horario</b-nav-item>
      </b-navbar-nav>
    </b-collapse>
  </b-navbar>
</template>
<script>
export default {
  name: 'MiNavbar'
}
</script>
<style scoped lang="scss">
.svg{
  color: white;
}
.navbar-light .navbar-brand {
    color: white;
}
.bg-info{
  background-color: #e25f23  !important;
}
.nav-item{
  color: white;
}
.navbar-light .navbar-nav .nav-link {
    color: rgb(255, 255, 255);
}
.navbar-light .navbar-brand{
  color: white;
}
.navbar-toggler-icon{
  color: white;
}
</style>

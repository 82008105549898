<template>
  <div id="app">
    <router-view/>
    <MiFooter/>
  </div>
</template>
<script>
import MiFooter from '@/components/FooterComponent.vue'
export default {
  components: {
    MiFooter
  },
  comments: {
  },
  data () {
    return {
    }
  }
}
</script>
<style lang="scss">
html{
  font-family:Georgia, 'Times New Roman', Times, serif;
}
</style>
